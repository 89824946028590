/* eslint-disable max-len */
import React from 'react';

const FreeShipping = () => (
  <svg width="87" height="62" viewBox="0 0 87 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M80.3674 21.5717C79.2009 19.1386 76.7382 17.541 73.9861 17.541H58.7672V45.318H83.7021V32.8154C82.5763 28.8973 81.7319 25.9587 81.169 23.9996L80.3674 21.5717Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M80.3674 21.5717C79.2009 19.1386 76.7382 17.541 73.9861 17.541H58.7672V45.318H83.7021V32.8154C82.5763 28.8973 81.7319 25.9587 81.169 23.9996L80.3674 21.5717Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="80.772" y="37.679" width="2.92084" height="3.65199" stroke="#333333" strokeWidth="1.5" />
    <path d="M62.2977 35.6735C62.2978 35.6755 62.2979 35.6779 62.2975 35.6799L62.2964 35.8867C62.2952 36.1272 62.5713 36.3188 62.9162 36.3172L65.3358 36.3054C65.6845 36.3037 65.9725 36.1052 65.979 35.8622C65.9789 35.8602 65.9793 35.8578 65.9792 35.8555L65.9799 35.6487" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.96545 33.9383V10.7729H58.7519V45.3177H3.96545V41.9827V33.9383Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.5823 4.40454H39.1635V11.6059H58.4388V15.0856H39.1635V25.6392L34.7248 21.3688L31.3212 24.4859L28.0934 21.3759L23.5823 25.5787V15.0856H4.38464V11.6059H23.5823V4.40454Z" fill="#EEEEEE" style={{ mixBlendMode: 'multiply' }} />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.96545 33.9383V10.7729H58.7519V45.3177H3.96545V41.9827V33.9383Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="0.893311" y="0.924805" width="60.9308" height="10.3867" rx="1" fill="white" />
    <rect x="0.893311" y="0.924805" width="60.9308" height="10.3867" rx="1" fill="white" stroke="#333333" strokeWidth="1.5" />
    <mask id="path-10-inside-1_728_42999" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.5681 0.924805H39.1493V22.1595L34.7107 17.8891L31.307 21.0062L28.0793 17.8962L23.5681 22.0989V0.924805ZM74.0835 19.7779H61.7988L61.7916 31.9571H80.4331H80.4367L77.7246 22.5329C77.2577 20.9119 75.7602 19.7779 74.0835 19.7779Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M23.5681 0.924805H39.1493V22.1595L34.7107 17.8891L31.307 21.0062L28.0793 17.8962L23.5681 22.0989V0.924805ZM74.0835 19.7779H61.7988L61.7916 31.9571H80.4331H80.4367L77.7246 22.5329C77.2577 20.9119 75.7602 19.7779 74.0835 19.7779Z" fill="#BFBFBF" />
    <path d="M39.1493 0.924805H42.1493V-2.0752H39.1493V0.924805ZM23.5681 0.924805V-2.0752H20.5681V0.924805H23.5681ZM39.1493 22.1595L37.0694 24.3214L42.1493 29.2087V22.1595H39.1493ZM34.7107 17.8891L36.7906 15.7272L34.7613 13.7748L32.6845 15.6767L34.7107 17.8891ZM31.307 21.0062L29.2255 23.1666L31.2549 25.1219L33.3332 23.2186L31.307 21.0062ZM28.0793 17.8962L30.1608 15.7358L28.114 13.7637L26.0343 15.7012L28.0793 17.8962ZM23.5681 22.0989H20.5681V28.994L25.6131 24.294L23.5681 22.0989ZM61.7988 19.7779V16.7779H58.8005L58.7988 19.7761L61.7988 19.7779ZM61.7916 31.9571L58.7916 31.9553L58.7899 34.9571H61.7916V31.9571ZM80.4367 31.9571V34.9571H84.4217L83.3197 31.1275L80.4367 31.9571ZM77.7246 22.5329L80.6076 21.7033L80.6074 21.7025L77.7246 22.5329ZM39.1493 -2.0752H23.5681V3.9248H39.1493V-2.0752ZM42.1493 22.1595V0.924805H36.1493V22.1595H42.1493ZM32.6307 20.051L37.0694 24.3214L41.2293 19.9976L36.7906 15.7272L32.6307 20.051ZM33.3332 23.2186L36.7368 20.1015L32.6845 15.6767L29.2809 18.7938L33.3332 23.2186ZM25.9977 20.0566L29.2255 23.1666L33.3886 18.8458L30.1608 15.7358L25.9977 20.0566ZM25.6131 24.294L30.1242 20.0912L26.0343 15.7012L21.5232 19.9039L25.6131 24.294ZM20.5681 0.924805V22.0989H26.5681V0.924805H20.5681ZM61.7988 22.7779H74.0835V16.7779H61.7988V22.7779ZM64.7916 31.9589L64.7988 19.7797L58.7988 19.7761L58.7916 31.9553L64.7916 31.9589ZM80.4331 28.9571H61.7916V34.9571H80.4331V28.9571ZM80.4367 28.9571H80.4331V34.9571H80.4367V28.9571ZM74.8416 23.3625L77.5537 32.7867L83.3197 31.1275L80.6076 21.7033L74.8416 23.3625ZM74.0835 22.7779C74.4098 22.7779 74.7413 23.0142 74.8418 23.3633L80.6074 21.7025C79.7741 18.8096 77.1106 16.7779 74.0835 16.7779V22.7779Z" fill="white" mask="url(#path-10-inside-1_728_42999)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.1635 0.924805H23.5823L23.5823 22.0989L28.0934 17.8962L31.3212 21.0062L34.7248 17.8891L39.1635 22.1595V0.924805Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M61.7988 19.7778H74.0835C75.7602 19.7778 77.2577 20.9118 77.7246 22.5328L80.4367 31.957H80.4331H61.7916L61.7988 19.7778Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="1.56482" y="45.3179" width="84.2361" height="5.3094" rx="1" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.95154 50.2194H85.4093V47.9727H1.95154V50.2194Z" fill="#EEEEEE" style={{ mixBlendMode: 'multiply' }} />
    <path fillRule="evenodd" clipRule="evenodd" d="M70.5585 41.5649C65.4099 41.5649 61.2363 45.8581 61.2363 51.1535C61.2363 56.4489 65.4099 60.7421 70.5585 60.7421C75.7072 60.7421 79.8808 56.4489 79.8808 51.1535C79.8808 45.8581 75.7072 41.5649 70.5585 41.5649Z" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4984 41.5649C13.3497 41.5649 9.17615 45.8581 9.17615 51.1535C9.17615 56.4489 13.3497 60.7421 18.4984 60.7421C23.647 60.7421 27.8206 56.4489 27.8206 51.1535C27.8206 45.8581 23.647 41.5649 18.4984 41.5649Z" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M70.5586 45.7864C73.523 45.7864 75.9256 48.1894 75.9256 51.1534C75.9256 54.1174 73.523 56.5204 70.5586 56.5204C67.5945 56.5204 65.1915 54.1174 65.1915 51.1534C65.1915 48.1894 67.5945 45.7864 70.5586 45.7864Z" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4984 45.7864C21.4628 45.7864 23.8654 48.1894 23.8654 51.1534C23.8654 54.1174 21.4628 56.5204 18.4984 56.5204C15.5344 56.5204 13.1313 54.1174 13.1313 51.1534C13.1313 48.1894 15.5344 45.7864 18.4984 45.7864Z" fill="white" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

export default FreeShipping;
