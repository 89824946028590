import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as Icons from './icons';

function InfoSlide({
  title_label: titleLabel,
  picture,
  description,
  disclaimer,
  target_url: targetUrl,
  link_label: linkLabel,
  tabIndex = -1,
}) {
  const VALID_ICONS = Object.keys(Icons);
  const IconImage = VALID_ICONS.includes(picture) ? Icons[picture] : Icons.shipping;

  const infoSlideTitleClass = classNames('info-slide__title', {
    'info-slide__disclaimer': disclaimer,
  });

  return (
    <div key={titleLabel} className="info-slide" tabIndex={tabIndex}>
      <div className="img-container">
        <IconImage />
      </div>
      <h2 className={infoSlideTitleClass}>{titleLabel}</h2>
      <p>{description}</p>
      { disclaimer && (
        <p>
          <small className="info-slide__shipping-conditions">{disclaimer}</small>
        </p>
      )}
      { targetUrl && <a href={targetUrl}>{linkLabel}</a> }
    </div>
  );
}

InfoSlide.propTypes = {
  description: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  link_label: PropTypes.string,
  picture: PropTypes.string.isRequired,
  target_url: PropTypes.string,
  title_label: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
};

export default InfoSlide;
