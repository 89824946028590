/* eslint-disable max-len */
import React from 'react';

const Shipping = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="98" height="72" viewBox="0 0 67 50" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M42.0505 3.56372H24.5414V12.0715H4.92419V15.7017H24.5414V27.8398L28.9187 24.2959L33.2959 27.8398L37.6732 24.2959L42.0505 27.8398V15.7017H62.6518V12.0715H42.0505V3.56372ZM4.24841 45.3528H62.6519V48.6551H4.24841V45.3528Z" fill="#EEEEEE" style={{ mixBlendMode: 'multiply' }} />
    <path fillRule="evenodd" clipRule="evenodd" d="M62.6985 44.0214V47.6533C62.6985 48.2056 62.2508 48.6533 61.6985 48.6533H5.24841C4.69613 48.6533 4.24841 48.2056 4.24841 47.6533V12.0715H62.6985V35.3689V44.0214Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="0.791382" y="1.34497" width="65.4171" height="10.6971" rx="2" stroke="#333333" strokeWidth="1.5" />
    <mask id="path-4-inside-1_726_42698" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M41.7068 1.34497H24.8383V24.1828L29.0554 20.8254L33.2726 24.1828L37.4897 20.8254L41.7068 24.1828V1.34497ZM10.2224 42.9426H21.4945V35.4834H10.2224V42.9426Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M41.7068 1.34497H24.8383V24.1828L29.0554 20.8254L33.2726 24.1828L37.4897 20.8254L41.7068 24.1828V1.34497ZM10.2224 42.9426H21.4945V35.4834H10.2224V42.9426Z" fill="#BFBFBF" />
    <path d="M24.8383 1.34497V-0.155029H23.3383V1.34497H24.8383ZM41.7068 1.34497H43.2068V-0.155029H41.7068V1.34497ZM24.8383 24.1828H23.3383V27.2944L25.7726 25.3563L24.8383 24.1828ZM29.0554 20.8254L29.9897 19.6519L29.0554 18.908L28.1211 19.6519L29.0554 20.8254ZM33.2726 24.1828L32.3383 25.3563L33.2726 26.1002L34.2068 25.3563L33.2726 24.1828ZM37.4897 20.8254L38.424 19.6519L37.4897 18.908L36.5554 19.6519L37.4897 20.8254ZM41.7068 24.1828L40.7726 25.3563L43.2068 27.2944V24.1828H41.7068ZM21.4945 42.9426V44.4426H22.9945V42.9426H21.4945ZM10.2224 42.9426H8.72241V44.4426H10.2224V42.9426ZM21.4945 35.4834H22.9945V33.9834H21.4945V35.4834ZM10.2224 35.4834V33.9834H8.72241V35.4834H10.2224ZM24.8383 2.84497H41.7068V-0.155029H24.8383V2.84497ZM26.3383 24.1828V1.34497H23.3383V24.1828H26.3383ZM28.1211 19.6519L23.904 23.0093L25.7726 25.3563L29.9897 21.9989L28.1211 19.6519ZM34.2068 23.0093L29.9897 19.6519L28.1211 21.9989L32.3383 25.3563L34.2068 23.0093ZM36.5554 19.6519L32.3383 23.0093L34.2068 25.3563L38.424 21.9989L36.5554 19.6519ZM42.6411 23.0093L38.424 19.6519L36.5554 21.9989L40.7726 25.3563L42.6411 23.0093ZM40.2068 1.34497V24.1828H43.2068V1.34497H40.2068ZM21.4945 41.4426H10.2224V44.4426H21.4945V41.4426ZM19.9945 35.4834V42.9426H22.9945V35.4834H19.9945ZM10.2224 36.9834H21.4945V33.9834H10.2224V36.9834ZM11.7224 42.9426V35.4834H8.72241V42.9426H11.7224Z" fill="white" mask="url(#path-4-inside-1)" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.0336 1.34497H41.5054V24.1828L37.3874 20.8489L33.2695 24.1828L29.1515 20.8489L25.0336 24.1828V1.34497Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="21.4945" y="42.9426" width="11.2721" height="7.45919" transform="rotate(180 21.4945 42.9426)" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49.5101 37.5531H52.3901V34.6731H49.5101V37.5531Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M49.5101 42.4906H52.3901V39.6106H49.5101V42.4906Z" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M57.5659 37.5531H56.6225H55.2458H54.6859V34.6731H57.5659V37.5531Z" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

export default Shipping;
