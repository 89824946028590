/* eslint-disable max-len */
import React from 'react';

const Payment = () => (
  <svg width="110" height="72" viewBox="0 0 78 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.85291 40.7839C1.85291 43.1035 4.10664 44.9906 6.91159 45.0481L7.04347 45.0494H71.0952C73.9169 45.0494 76.2126 43.198 76.2825 40.8923L76.2842 40.7839V43.8877C76.2842 46.7657 73.961 49.0988 71.0952 49.0988H7.04347C4.17762 49.0988 1.85291 46.7657 1.85291 43.8877V40.7839Z" fill="#EEEEEE" style={{ mixBlendMode: 'multiply' }} />
    <path d="M72.4767 48.9389H5.45737C3.3129 48.9389 1.57422 47.1885 1.57422 45.0296V5.01065C1.57422 2.85172 3.3129 1.10132 5.45737 1.10132H72.4767C74.6212 1.10132 76.3599 2.85172 76.3599 5.01065V45.0296C76.3599 47.1885 74.6212 48.9389 72.4767 48.9389" stroke="#333333" strokeWidth="1.5" />
    <path d="M10.2385 38.6928H40.6566" stroke="#333333" strokeWidth="1.5" />
    <path d="M45.6945 38.6928H52.3441" stroke="#333333" strokeWidth="1.5" />
    <path d="M10.2385 30.8458H20.1301" stroke="#333333" strokeWidth="1.5" />
    <circle cx="15.1843" cy="11.9762" r="5.29749" fill="#BFBFBF" stroke="white" strokeWidth="1.5" />
    <circle cx="15.1843" cy="11.9762" r="6.04749" stroke="#333333" strokeWidth="1.5" />
    <ellipse cx="24.7797" cy="11.9762" rx="6.04749" ry="6.04749" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M25.8813 30.8458H35.7729" stroke="#333333" strokeWidth="1.5" />
    <path d="M41.5243 30.8458H51.4159" stroke="#333333" strokeWidth="1.5" />
    <path d="M57.1671 30.8458H67.0587" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

export default Payment;
