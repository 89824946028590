import React from 'react';

import ShoppingInfo from '../../components/site-shopping-info/desktop';

function DesktopShoppingInfo(props) {
  return (
    <div id="section-info">
      <ShoppingInfo {...props} type="site-shopping-info" />
    </div>
  );
}

export default DesktopShoppingInfo;
